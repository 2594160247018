/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

@font-face {
  font-family: "ClashGrotesk";
  src: url("/public/Fonts/ClashGrotesk-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "ClashGroteskRegular";
  src: url("/public/Fonts/ClashGrotesk-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins";
  src: url("/public/Fonts/Poppins-Regular.ttf") format("truetype");
}

body,
.common-label {
  font-family: "ClashGrotesk";
}
input,
::placeholder,
button,
Button {
  font-family: "Poppins" !important;
}
