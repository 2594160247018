* {
  margin: 0;
  padding: 0;
  border: none;
}
.profile__body {
  background-color: #f8f9fb;
  width: 100%;
  padding-left: 8px;
  /* height: 100%; */
}
.profile__main {
  max-width: 100%;
  width: 100%;
  /* max-width: 1000px; */
  /* height: 100%; */
  margin-top: 35px;
  /* margin-left: 50px; */
  border-radius: 20px;
  background-color: #fff;
}
.profile__main-image {
  padding-left: 50px;
  padding-top: 50px;
  margin-bottom: 31px;
}
/* .profile__main-image img {
  width: 130px;
  height: 130px;
} */

.profile-img{
  width: 130px;
  height: 130px;

}
.profile-img-updated{
  width: 130px;
  height: 130px;
  border-radius: 100px;

}
.profile__main-values {
  gap: 20px;
  display: flex;
  flex-wrap: wrap;
  margin-left: 50px;
}
.profile__main-input {
  display: flex;
  flex-direction: column;
  position: relative;
}
.profile__main-input label {
  font-family: "ClashGrotesk";

  font-size: 16px;
  font-weight: 500;
  line-height: 19.68px;
  text-align: left;
  color: #0a1126;
  padding-bottom: 10px;
}
.profile__main-input input {
  width: 440px;
  height: 64px;
  /* padding: 20px 278px 20px 20px; */
  gap: 10px;
  border-radius: 12px;
  border: 1px solid #eaeaea;
}
#inputField {
  padding-left: 52px;
}
.profile__main-input::before {
  content: "";
  position: absolute;
  left: 20px; /* Adjust the position of the image */
  top: 69%;
  transform: translateY(-50%);
  width: 20px; /* Adjust the size of the image */
  height: 20px;
  background-image: url("/public/Images/Profile/user-03.svg"); /* Path to your image */
  background-size: cover;
}

.profile__main-input1 {
  display: flex;
  flex-direction: column;
  position: relative;
}
.profile__main-input1 label {
  font-family: "ClashGrotesk";
  font-size: 16px;
  font-weight: 500;
  line-height: 19.68px;
  text-align: left;
  color: #0a1126;
  padding-bottom: 10px;
}
.profile__main-input1 input {
  width: 440px;
  height: 64px;
  /* padding: 20px 278px 20px 20px; */
  gap: 10px;
  border-radius: 12px;
  border: 1px solid #eaeaea;
}
#inputField {
  padding-left: 52px;
}
.profile__main-input1::before {
  content: "";
  position: absolute;
  left: 20px; /* Adjust the position of the image */
  top: 68%;
  transform: translateY(-50%);
  width: 20px; /* Adjust the size of the image */
  height: 20px;
  background-image: url("/public/Images/Profile/phone-call-01.svg"); /* Path to your image */
  background-size: cover;
}
.profile__main-input2 {
  display: flex;
  flex-direction: column;
  position: relative;
}
.profile__main-input2 label {
  font-family: "ClashGrotesk";
  font-size: 16px;
  font-weight: 500;
  line-height: 19.68px;
  text-align: left;
  color: #0a1126;
  padding-bottom: 10px;
}
.profile__main-input2 input {
  width: 440px;
  height: 64px;
  /* padding: 20px 278px 20px 20px; */
  gap: 10px;
  border-radius: 12px;
  border: 1px solid #eaeaea;
}
#inputField {
  padding-left: 52px;
}
.profile__main-input2::before {
  content: "";
  position: absolute;
  left: 20px; /* Adjust the position of the image */
  top: 69%;
  transform: translateY(-50%);
  width: 20px; /* Adjust the size of the image */
  height: 20px;
  background-image: url("/public/Images/Profile/at-sign.svg"); /* Path to your image */
  background-size: cover;
}
.profile__main-input3 {
  display: flex;
  flex-direction: column;
  position: relative;
}
.profile__main-input3 label {
  font-family: "ClashGrotesk";
  font-size: 16px;
  font-weight: 500;
  line-height: 19.68px;
  text-align: left;
  color: #0a1126;
  padding-bottom: 10px;
}
.profile__main-input3 input {
  width: 440px;
  height: 64px;
  /* padding: 20px 278px 20px 20px; */
  gap: 10px;
  border-radius: 12px;
  border: 1px solid #eaeaea;
}
#inputField {
  padding-left: 52px;
}
.profile__main-input3::before {
  content: "";
  position: absolute;
  left: 20px; /* Adjust the position of the image */
  top: 67%;
  transform: translateY(-50%);
  width: 20px; /* Adjust the size of the image */
  height: 20px;
  background-image: url("/public/Images/Profile/lock-01.svg"); /* Path to your image */
  background-size: cover;
}
.profile-eye {
  width: 20px;
  height: 20px;
}
.profile__main-input3 button {
  position: absolute;
  transform: translateY(-50%, -50%);
  right: 0;
  bottom: 0;
  padding-right: 20px;
  padding-bottom: 22px;
  background-color: transparent;
}

.profile__main-btn {
  margin-top: 50px;
  margin-left: 50px;
  padding-bottom: 49px !important;
}
.profile__main-btn button {
  width: 266px;
  height: 53px;
  border-radius: 12px;
  gap: 10px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: center;
  color: #fff;
  background-color: #0a1126;
  cursor: pointer;
}
.profile__main-btn button:hover {
  border: 1px solid #0a1126 !important;
  background-color: #ffffff !important;
  color: #0a1126 !important;
}
.profile__main-input4 {
  display: flex;
  flex-direction: column;
  position: relative;
}
.profile__main-input4 label {
  font-family: "ClashGrotesk";
  font-size: 16px;
  font-weight: 550;
  line-height: 19.68px;
  text-align: left;
  color: #0a1126;
  padding-bottom: 10px;
}
.profile__main-input4 input {
  width: 440px;
  height: 64px;
  /* padding: 20px 278px 20px 20px; */
  gap: 10px;
  border-radius: 12px;
  border: 1px solid #eaeaea;
}
#inputField {
  padding-left: 52px;
}
.profile__main-input4::before {
  content: "";
  position: absolute;
  left: 20px; /* Adjust the position of the image */
  top: 67%;
  transform: translateY(-50%);
  width: 20px; /* Adjust the size of the image */
  height: 20px;
  background-image: url("/public/Images/Profile/orgnization.svg"); /* Path to your image */
  background-size: cover;
}

/* @media (max-width: 600px) {
  .profile__main {
    margin-left: 0px;
  }
} */

@media (max-width: 480px) {
  .profile__main-input input {
    width: 100%;
  }
  .profile__main-input1 input {
    width: 100%;
  }
  .profile__main-input2 input {
    width: 100%;
  }
  .profile__main-input3 input {
    width: 100%;
  }
  .profile__main-input4 input {
    width: 100%;
  }
}

/* for hover */
.profile__main-input input:hover {
  border: 1.5px solid #0161f033;
  box-shadow: 0 0 0 1.5px #0161f033;
}
.profile__main-input1 input:hover {
  border: 1.5px solid #0161f033;
  box-shadow: 0 0 0 1.5px #0161f033;
}
.profile__main-input2 input:hover {
  border: 1.5px solid #0161f033;
  box-shadow: 0 0 0 1.5px #0161f033;
}
.profile__main-input3 input:hover {
  border: 1.5px solid #0161f033;
  box-shadow: 0 0 0 1.5px #0161f033;
}
.profile__main-input4 input:hover {
  border: 1.5px solid #0161f033;
  box-shadow: 0 0 0 1.5px #0161f033;
}
input:focus {
  outline: #eaeaea;
}

@media (max-width: 768px) {
  .profile__main {
    margin-top: 20px !important;
  }
  .profile__main-values {
    margin-left: 30px;
    margin-right: 30px;
  }
  .profile__main-image {
    padding-left: 30px;
    margin-bottom: 30px;
  }
  .profile__main-image img {
    width: 64px;
    height: 64px;
  }
  .profile__main-input,
  .profile__main-input4,
  .profile__main-input2,
  .profile__main-input1,
  .profile__main-input3 {
    width: 100%;
  }
  .profile__main-input label {
    font-size: 14px;
    line-height: 17.22px;
  }
  .profile__main-input input,
  .profile__main-input4 input,
  .profile__main-input2 input,
  .profile__main-input1 input,
  .profile__main-input3 input {
    width: 100%;
  }
  .profile__main-btn{
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 30px;
    padding-bottom: 30px !important;
  }
  .profile__main-btn button{
    width: 100%;
  }
}


.del{
  padding: 50px !important;
  font-family: "ClashGrotesk";
}