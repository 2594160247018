.smart-contract-modal .modal-dialog {
  max-width: 580px;
}
.smart-contract-modal {
  backdrop-filter: blur(5px) !important;
}
.smart-contract-modal .modal-header {
  border-bottom: none !important;
}
.smart-contract-modal .modal-content {
  border-radius: 20px !important;
}
.add-project__header {
  display: flex;
  justify-content: space-between !important;
  width: 100%;
  border: none !important;
}
.add-contract-m-heading h6 {
  font-family: "ClashGrotesk";
  font-size: 24px;
  font-weight: 550;
  line-height: 29.52px;
  text-align: left;
  color: #0a1126;
  padding-bottom: 7px;
}
.add-contract-m-heading p {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #858585;
}
.add-contract__input {
  display: flex;
  flex-direction: column;
}
.contract-name-head {
  font-family: "ClashGrotesk";
  font-size: 16px;
  font-weight: 500;
  line-height: 19.68px;
  text-align: left;
  color: #0a1126;
  padding-bottom: 10px;
}
.add-contract__input input {
  width: 440px;
  height: 64px;
  border-radius: 12px;
  border: 1px solid #eaeaea;
  padding-left: 22px;
}

.add-contract__input input::placeholder {
  color: #afafaf !important;
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.contract-top {
  margin-top: 20px;
}
.continue-add-btn {
  width: 440px !important;
  height: 67px !important;
  border: 1px solid #166fbf0a !important;
  background: #0a1126 !important;
  padding: 20px !important;
  border-radius: 12px !important;
  text-transform: none !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  line-height: 27px !important;
  color: #ffffff !important;
  margin-top: 50px !important;
  font-family: "ClashGrotesk";
  /* margin-bottom: 60px !important; */
}

.continue-add-btn:hover {
  border: 1px solid #0a1126 !important;
  background-color: #ffffff !important;
  color: #0a1126 !important;
}
.img-dollar {
    position: absolute;
    top: 50%;
    left: 0px;
    padding-left: 22px !important;
    transform: translateY(-50%);
}
.add-contract__input input{
    width: 440px;
    height: 64px;
    border-radius: 12px;
    border: 1px solid #eaeaea;
    padding-left: 22px;
}
.input-dollar-space{
    padding-left: 53px !important;
}
@media (max-width: 768px) {
    .input-dollar-space{
        padding-left: 53px !important;
    }
  .add-contract__input {
    width: 100%;
  }
  .add-contract__input input {
    width: 100% !important;
  }
  .add-contract-close {
    display: flex;
    align-items: flex-end;
    justify-content: end;
  }
  .add-contract-header {
    flex-direction: column-reverse;
    gap: 11px;
  }
  .add-contract-m-heading h6 {
    font-size: 20px;
    line-height: 24.6px;
  }
  .add-contract-m-heading p {
    font-size: 14px;
    line-height: 21px;
  }
  .smart-contract-modal .modal-header {
    padding: 35px 30px 0px 30px !important;
  }
  .smart-contract-modal .modal-body {
    padding: 40px 30px 70px 30px !important;
  }
  .contract-name-head {
    font-size: 14px !important;
    line-height: 17.22px !important;
  }
  .continue-add-btn {
    width: 100% !important;
    margin-top: 40px !important;
  }
}
