.project-docs-modal .modal-content {
  border-radius: 20px !important;
}
.project-docs-modal .modal-dialog {
  max-width: 684px;
}
.project-docs-modal .modal-header {
  /* border-radius: 20px 20px 0 0 !important; */
  border-bottom: none !important;
}
.project-docs-body {
  display: flex;
  justify-content: center;
  margin-bottom: 60px;
  position: relative;
}
.project-docs-body img {
  width: 544px;
  height: 296px;
}

.project-docs-body-images1 {
  position: absolute;
  top: 32%;
  left: 0%;
  padding-left: 39px;
}
.project-docs-body-images2 {
  position: absolute;
  top: 32%;
  right: 0%;
  padding-right: 39px;
}

@media (max-width: 768px) {
  .project-docs-modal .modal-header {
    padding: 30px 30px 0 30px !important;
  }
  .add-project__header {
    margin-bottom: 40px;
  }
  .add-project__main-header h6 {
    font-size: 20px;
    line-height: 24.6px;
  }
  .project-docs-body img {
    width: 100%;
    height: 100%;
    padding-left: 46.5px;
    padding-right: 46.5px;
  }
  .project-docs-body {
    margin-bottom: 0px;
  }
  .project-docs-modal .modal-body {
    padding-bottom: 30px !important;
  }
  .project-docs-body-images1,
  .project-docs-body-images2 {
    top: 45%;
    /* width: 34px;
    height: 34px; */
  }
  .project-docs-modal .modal-dialog {
    max-width: 100%;
  }
}
