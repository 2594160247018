.row-forgot {
  align-items: center;
  justify-content: center;
}
.forgot-col-lhs {
  display: flex;
  justify-content: center !important;
  height: 100vh !important;
  overflow-y: auto !important;
}
.LHS-forgot {
  /* padding-top: 224px !important; */
}
.back-div {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  padding-bottom: 50px;
}
.back-forgot {
  font-size: 16px;
  font-weight: 550;
  line-height: 19.68px;
  text-align: center;
  color: #0a1126;
  font-family: "ClashGrotesk";
}

.Forgot-Submit-btn {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 440px;
  height: 67px;
  padding: 20px !important;
  border-radius: 12px !important;
  background: #0a1126 !important;
  text-transform: capitalize !important;
  font-family: "Poppins";

  font-size: 18px !important;
  font-weight: 500 !important;
  line-height: 27px !important;
  color: #ffffff !important;
  margin-top: 50px !important;
}

.Forgot-Submit-btn:hover {
  border: 1px solid #0a1126 !important;
  background-color: #ffffff !important;
  color: #0a1126 !important;
}
.new-bg {
  background-image: url("/public/Images/Auth/login-bg.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  height: 100%;
  /* height: 100vh; */
}

@media (min-width: 1440px) {
  .forgot-col-lhs {
    display: flex;
    align-items: center !important;
    justify-content: center !important;
    
  }
  .LHS-forgot {
    /* padding-top: 224px !important; */
  }
  .row-forgot {
    align-items: normal !important;
    justify-content: normal !important;
  }
}

@media (max-width: 992px) {
  .forgot-col-lhs {
    align-items: center !important;
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .back-forgot {
    font-size: 14px;
    line-height: 17.22px;
  }
  .back-div img {
    width: 16px;
    height: 16px;
  }
  .Forgot-Submit-btn {
    font-size: 16px !important;
    line-height: 24px !important;
  }
}

@media (max-width: 768px) {
  .Forgot-Submit-btn {
    width: 100% !important;
  }
  .login-subtxt br {
    display: none;
  }
}
