.newpass-col-lhs {
  display: flex;
  /* align-items: center !important; */
  /* align-items: flex-start !important; */
  justify-content: center !important;

  height: 100vh !important;
  overflow-y: auto !important;
}
.newpass-input {
  border: 1px solid #eaeaea !important;
  border-radius: 12px !important;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  padding-left: 44px !important;
  /* font-family: "Montserrat", sans-serif !important; */
  width: 440px !important;
  height: 64px;
  position: relative;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  color: #0a1126 !important;
}
.newpass-input::placeholder {
  /* font-family: Poppins; */
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  color: #afafaf !important;

  /* text-align: left; */
}

.newpass-img-lock {
  position: absolute;
  top: 57%;
  left: 20px;
  /* transform: translateY(-50%); */
}

.newpass-img-eye {
  position: absolute !important;
  top: 45%;
  right: 0px;
  padding-right: 20px !important;
}

@media (min-width: 1440px) {
  .newpass-col-lhs {
    display: flex;
    align-items: center !important;
    justify-content: center !important;
    
  }
}

@media (max-width: 992px) {
  .newpass-col-lhs {
    align-items: center !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
  .newpass-input {
    width: 100% !important;
  }

  .LHS-newpass {
    width: 100%;
  }
  .newpass-input,
  .newpass-input::placeholder {
    font-size: 14px !important;
    line-height: 21px !important;
  }
}
