.signup-verify-lhs {
  display: flex;
  justify-content: center !important;
  align-items: center;
  height: 100vh !important;
  overflow-y: auto !important;
}
.otp-inputs-main {
  display: flex;
  align-items: flex-start;
  gap: 13.33px;
}
.otp-input {
  border: 1px solid #eaeaea !important;
  border-radius: 12px !important;
  padding: 10px 20px !important;
  /* font-family: "Montserrat", sans-serif !important; */
  width: 100px !important;
  height: 100px;
  font-size: 40px !important;
  font-weight: 500 !important;
  line-height: 49.2px !important;
  color: #0a1126 !important;
  text-align: center;
}
.email-input-contain input[type="number"]::-webkit-inner-spin-button,
.email-input-contain input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  display: none !important;
  margin: 0;
}
.email-input-contain input[type="number"] {
  -moz-appearance: textfield; /* For Firefox */
}

.otp-input:focus {
  border-color: #0a1126 !important ;
  box-shadow: none !important;
}

.receive-code-div {
  padding-top: 50px !important;
}
@media (min-width: 1440px) {
  .email-input-contain input[type="number"]::-webkit-inner-spin-button,
  .email-input-contain input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none !important;
    display: none !important;
    margin: 0;
  }
 
    .signup-verify-lhs {
      display: flex;
      align-items: center !important;
      justify-content: center !important;
      
    }
}
@media(max-width:992px)
{
  .signup-verify-lhs {
    align-items: center !important;
    padding-left: 15px !important;
    padding-right: 16px !important;
  }
  .otp-input{
font-size: 30px !important;
line-height: 36.9px !important;

  }
  .txt-btm{
    text-align: center;
  }
}
@media (max-width: 576px) {
  .otp-input {
    width: 100% !important;
    height: 100% !important;
  }
}
