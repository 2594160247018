/* General Layout */
.meet-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
  background-color: #121212;
  color: #ffffff;
  font-family: Arial, sans-serif;
  padding: 20px;
  overflow: auto;
}

.meeting-header {
  display: none;
}

.meeting-info {
  font-size: 14px;
  margin-top: 5px;
  opacity: 0.8;
}

/* Video Container */
.video-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.video-grid.ferfer {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 15px;
  width: 100%;
  max-width: unset;
}
.video-grid.ferfer .video-box {
  height: 680px;
}
.video-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
  width: 100%;
  max-width: unset;
}

.video-box {
  position: relative;
  width: 100%;
  height: 340px;
  background: #2a2a2a;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.user-id-overlay {
  position: absolute;
  bottom: 5px;
  left: 5px;
  background: rgba(0, 0, 0, 0.6);
  padding: 5px;
  font-size: 12px;
  border-radius: 5px;
}

/* Controls */
.controls {
  display: flex;
  gap: 15px;
  margin-top: 20px;
  position: fixed;
  bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  background-color: white;
  box-shadow: #121212;
  padding: 16px;
  border-radius: 16px;
  width: fit-content;
}

.control-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  border-radius: 50px;
  border: none;
  background: #3a3a3a;
  color: #ffffff;
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
}

.control-btn:hover {
  background: #555555;
}

.control-btn.on {
  background: #0f9d58;
}

.control-btn.off {
  background: #c62828;
}

.control-btn.end {
  background: #d32f2f;
}

.control-btn span {
  margin-left: 8px;
}

/* Sidebar for Participants */
.sidebar {
  position: absolute;
  top: 50px;
  right: 20px;
  background: #1f1f1f;
  width: 250px;
  border-radius: 10px;
  padding: 15px;
}

.participant-list {
  margin-top: 10px;
}

.participant {
  padding: 8px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

/* Connecting Text */
.connecting-text {
  font-size: 18px;
  color: #bbb;
  margin-top: 20px;
}
.profile-photo-video {
  width: 200px;
  height: 200px;
  border-radius: 100px;
  align-self: center;
  object-fit: cover;
  object-position: top;

  border-radius: 100px;
  z-index: 2;
  background: #0a1126;
}
