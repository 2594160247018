.client__copy-btn {
  width: 440px;
  height: 48px;
  border-radius: 12px !important;
  border: 1px solid #166fbf3d !important;
  background-color: #166fbf0d !important;
  display: flex;
  gap: 10px !important;
  /* font-family: Poppins; */
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  color: #166fbf !important;
  text-transform: none !important;
}
.client__copy-btn-img {
  width: 18px !important;
  height: 18px !important;
}
.client__copy-other {
  margin-top: 25px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 11px;
}
.client__copy-other p {
  /* font-family: Clash Grotesk Variable; */
  font-size: 12px;
  font-weight: 550;
  line-height: 14.76px;
  text-align: start;
  color: #0a1126;
  width: 26%;
}
.client__copy-other-border {
  background-color: #eaeaea;
  /* width: 339px; */
  width: 100%;
  height: 1px;
}
.client__copy-input {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.client__copy-input label {
  /* font-family: Clash Grotesk Variable; */
  font-size: 16px;
  font-weight: 500;
  line-height: 19.68px;
  text-align: left;
  color: #0a1126;
}
.client__copy-input input {
  width: 440px;
  height: 64px;
  border-radius: 12px;
  border: 1px solid #eaeaea;
  padding: 22px;
}

.client-project__btn {
  width: 440px !important;
  height: 67px !important;
  border: 1px solid #166fbf0a !important;
  background: #0a1126 !important;
  padding: 20px !important;
  border-radius: 12px !important;
  text-transform: none !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  line-height: 27px !important;
  color: #ffffff !important;
  margin-top: 50px !important;
  font-family: "ClashGrotesk";
}

.client-project__btn:hover {
  border: 1px solid #0a1126 !important;
  background-color: #ffffff !important;
  color: #0a1126 !important;
}
.client__userExist {
  display: flex;
  gap: 12px !important;
}
.client__userExist1 {
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  color: #f00a25;
}
.client__userExist2 {
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  color: #0a1126;
}
.client__userExist-border {
  width: 1px;
  height: 18px;
  background-color: #0a1126;
  /* font-weight: 275; */
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 275;
  line-height: 18px;
  text-align: left;
}

@media (max-width: 768px) {
  .client__copy-btn {
    width: 100% !important;
  }
  .client__copy-other {
    margin-top: 15px !important;
  }
  .client__copy-input {
    margin-top: 20px;
  }
  .client__copy-input label {
    font-size: 14px;
    line-height: 17.22px;
  }
  .client__copy-input input {
    width: 100%;
  }
  .client-project__btn {
    width: 100% !important;
    margin-top: 40px !important;
  }
  .client__userExist1,
  .client__userExist2 {
    font-size: 10px;
    line-height: 15px;
  }
  .client__userExist-border {
    height: 15px;
  }
  .client-project__btn {
    font-size: 16px !important;
    line-height: 24px !important;
    height: 64px !important;
    margin-bottom: 40px !important;
  }

}
