.container {
  display: flex;
  flex-direction: column;
  padding: 0px 16px;
  background: white;
  border-radius: 12px;
  width: 100%;
  max-width: 500px;
  margin-bottom: 15px;
}

.title {
  color: #333;
  font-size: 22px;
  margin-bottom: 8;
}

.canvasWrapper {
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  background: white;
}

.signatureCanvas {
  width: 100%;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
}

.buttonGroup {
  display: flex;
  gap: 10px;
  justify-content: end;
}

.button {
  background: #007bff;
  color: white;
  padding: 10px 16px;
  border: none;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  font-size: 16px;
  gap: 8px;
  cursor: pointer;
  transition: background 0.3s;
}

.button:hover {
  background: #0056b3;
}

.clearButton {
  background: #dc3545;
}

.clearButton:hover {
  background: #a71d2a;
}

.imageContainer {
  text-align: center;
  margin-top: 15px;
}

.subtitle {
  font-size: 18px;
  color: #444;
}

.signatureImage {
  max-width: 100%;
  height: auto;
  border-radius: 6px;
  border: 2px solid #ccc;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}
