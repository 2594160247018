.header__arrow-img{
    padding-right: 20px;
    padding-bottom: 4px;
    padding-left: 10px;
    cursor: pointer;
}
.contributor-client-btn
{
    display: flex;
    gap: 10px;
}