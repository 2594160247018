.mt-close {
  margin-top: 40px;
  /* margin-right: 40px; */
  display: flex;
  align-items: end;
  justify-content: end;
}

.remove-contributor-modal .modal-dialog {
  max-width: 520px !important;
}
.remove-contributor-modal .modal-header {
  border-radius: 20px 20px 0 0 !important;
  border-bottom: none !important;
  display: none !important;
}
.remove-contributor-modal .modal-body {
  padding: 0px !important;
  padding-left: 40px !important;
  margin-right: 40px !important;
  /* padding-top: 50px !important; */

  /* padding-bottom: 46px !important; */
}
.remove-contributor-modal .modal-content {
  border-radius: 20px !important;
  height: 545px;
}
.email-sent-body {
  display: flex;

  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.name-sent-head {
  padding-top: 30px !important;
  font-size: 30px !important;
  font-weight: 500 !important;
  line-height: 36.9px !important;
  text-align: center !important;
  color: #0a1126 !important;
  padding-bottom: 10px !important;
  padding-top: 30px !important;
}

.client-email-head {
  font-size: 22px;
  font-weight: 500;
  line-height: 27.06px;
  color: #166fbf;
  padding-bottom: 10px !important;
}

.btn-remove-contributor {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 440px !important;
  height: 67px !important;
  border: 1px solid #166fbf0a !important;
  background: #fd0707 !important;
  padding: 20px !important;
  border-radius: 12px !important;
  text-transform: none !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  line-height: 27px !important;
  color: #ffffff !important;
  /* margin-bottom: 40px !important; */
}
.confirmation-text {
  font-size: 22px;
  font-weight: 500;
  line-height: 27.06px;
  color: #aaaaaa;
  padding-bottom: 10px !important;
}
@media (max-width: 768px) {
  .btn-remove-contributor {
    width: 100% !important;
  }
  /* .remove-contributor-modal .modal-body {
    padding: 0px !important;
    padding-left: 40px !important;
    padding-right: 40px !important;
  } */
}
