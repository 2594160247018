.chatBox__main {
  width: 100%;
  border-radius: 20px;
  background-color: #ffffff;
  /* padding: 30px; */
}
.chatBox__main-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
  padding: 30px 30px 0px 30px;
}
.chatBox__main-header_left {
  display: flex;
  gap: 12px;
}
.chatBox__main-header_left-img {
  display: flex;
  justify-content: center;
  align-items: center;
}
.chatBox__main-header_left-img img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid #5ad964;
}
.chatBox__main-header_left-txt {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  /* text-align: left; */
}
.chatBox__main-header_left-txt h2 {
  /* font-family: Clash Grotesk Variable; */
  font-size: 20px;
  font-weight: 500;
  line-height: 24.6px;
  text-align: left;
  color: #0a1126;
  letter-spacing: 1px;
}
.chatBox__main-header_left-txt p {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: #d32121;
}
.chatBox__main-header_right {
  display: flex;
  gap: 5px;
}
.chatBox__main-header_right img {
  cursor: pointer;
}
.chatBox__border {
  border: 1px solid #ebeaea;
  margin-bottom: 10px;
}
.chatBox__main-sms {
  padding: 10px 0px 0px 30px;
}
.chatBox__main-sms__box-img {
  display: flex;
  gap: 10px;
  align-items: self-start;
}
.chatBox__main-sms__box-text {
  max-width: 85%;
  box-shadow: 0px 0px 12px #62616114;
  border-radius: 0 12px 12px 12px;
  overflow-wrap: break-word;
}
.userbox {
  max-width: 400px;
  width: 100%;
  min-width: 80px;
}
.chatBox__main-sms__box-text p {
  padding: 8px 16px !important;
  font-family: "Poppins";
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  text-align: left !important;
  color: #0a1126 !important;
  overflow-wrap: break-word;
}
.chatBox__main-sms__box-date {
  font-family: "Poppins";
  font-size: 10px;
  font-weight: 500;
  line-height: 15px;
  text-align: left;
  color: #858585;
  margin-top: 5px !important;
  margin-bottom: 12px !important;
}

.chatBox__main-sms1 {
  width: 100%;
  padding: 10px 30px 0 0;
  display: flex;
  justify-content: end;
  flex-direction: row-reverse;
}
.chatBox__main-sms__box-date1 {
  font-family: "Poppins";
  font-size: 10px;
  font-weight: 500;
  line-height: 15px;
  text-align: right;
  color: #858585;
  margin-top: 5px !important;
  margin-bottom: 12px !important;
}
.chatBox__main-sms__box-text1 {
  max-width: 430px;
  min-width: 100px;
  border: 1px solid #0a112633;
  border-radius: 12px;
  background-color: #0a1126;
  align-items: center;
  justify-content: center;
  overflow-wrap: break-word;
}

@media (max-width: 768px) {
  .chatBox__main-sms__box-text1 {
    width: 300px;
  }
}

@media (max-width: 500px) {
  .chatBox__main-sms__box-text1 {
    width: 100%;
  }
}
.chatBox__main-sms__box-text1 p {
  padding: 12px 15px !important;
  font-family: "Poppins";
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  text-align: left !important;
  color: #ffffff !important;
}

.chatBox__main-send {
  margin-left: 30px;
  margin-right: 30px;
  padding-bottom: 21px;
  position: relative;
}

.chatBox__main-send input {
  height: 63px;
  width: 100%;
  border: 1px solid #166fbf;
  border-radius: 15px;
  padding-left: 30px;
}
.chatBox__main-send button {
  position: absolute;
  /* transform: translateY(-50%); */
  right: 0;
  bottom: 0;
  padding-right: 15px;
  padding-bottom: 28px;
  background-color: transparent;
  padding-left: 51px;
}
input::placeholder {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: #858585;
}
.Appeal-btn {
  width: 125px;
  height: 48px;
  background: #0a1126;
  border-radius: 100px;
  padding: 13.5px 25px 13.5px 25px;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: center;
  color: #ffffff !important;
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.appeal-btn-mb {
  display: none;
}
.record-img {
  display: none;
}
.msgs-container {
  min-height: 200px;
  overflow: auto;
  height: 250px;
  padding: 16px 0;
}
.msgs-container::-webkit-scrollbar {
  display: none;
}
.text-call {
  text-align: center;
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  cursor: pointer;
  color: #858585;
  font-family: Poppins;
}
.text-call-name {
  font-family: "ClashGrotesk";

  font-size: 14px;
  font-weight: 500;
  line-height: 17.22px;
  color: #0a1126;
  cursor: pointer;
}
@media (max-width: 768px) {
  .chatBox__main-header {
    padding: 25px 18px 25px 30px;
    margin-bottom: 0;
  }
  .chatBox__main-header_left-txt h2 {
    font-size: 20px;
    line-height: 24.6px;
  }
  .appeal-btn-mb {
    display: block;
  }
  .Appeal-btn {
    display: none;
  }
  .every-txt {
    display: none;
  }
  .record-img {
    display: block;
    width: 24px;
    height: 24px;
  }
  .chatBox__main-header_left-txt {
    flex-direction: row;
    align-items: center;
    gap: 5px;
  }
  .call-img {
    width: 34px;
    height: 34px;
  }
  .chatBox__main-header_right {
    align-items: center;
  }
  .chatBox__main-sms__box-img {
    align-items: center;
  }
  .user-img-chat {
    width: 28px;
    height: 28px;
  }

  .chatBox__main-sms__box-text,
  .chatBox__main-sms__box-text1 {
    width: 300px;
  }
  .chatBox__main-sms {
    padding: 20px 0px 0px 0px;
  }
}

@media (max-width: 500px) {
  .chatBox__main-sms__box-text,
  .chatBox__main-sms__box-text1 {
    width: 100%;
  }
  .chatBox__main-sms1 {
    padding-left: 30px;
  }

  .chatBox__main-header {
    flex-wrap: wrap;
    gap: 10px;
  }
}

p.chatBodauser1 {
  padding: 0 8px !important;
  color: #121212ce !important;
  font-size: 14px !important;
}
p.chatBodate1 {
  padding: 4px 8px 2px !important;
  font-size: 10px !important;
  color: #121212 !important;
}
p.chate2 {
  text-align: right !important;
  padding: 2px 8px 4px !important;
  font-size: 10px !important;
  color: #767373 !important;
}
p.chatBodauser {
  text-align: right !important;
  padding: 4px 8px 0 !important;
  color: #ffffff !important;
  font-size: 14px !important;
}
p.chate1 {
  text-align: right !important;
  padding: 4px 8px !important;
  font-size: 10px !important;
  color: #ffffff !important;
}
.date-heading {
  font-weight: bold;
  font-size: 14px;
  color: gray;
  margin-top: 10px;
  margin-bottom: 5px;
  text-align: center; /* Center the text */
  width: 100%; /* Ensure it takes up full width to center */
  margin-left: auto; /* Center align */
  margin-right: auto; /* Center align */
}
