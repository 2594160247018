.dispute__main-heading {
  /* font-family: Clash Grotesk Variable; */
  font-size: 24px;
  font-weight: 550;
  line-height: 29.52px;
  text-align: left;
  color: #0a1126;
}
.dispute__main-header {
  display: flex;
  flex-direction: column;
  gap: 7px;
}
.dispute__main-paragraph {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #858585;
  padding-top: 7px;
}
/* .dispute__body{
    display: flex;
    flex-direction: column;
    gap: 20px;
} */
.dispute__body-head {
  /* font-family: Clash Grotesk Variable; */
  font-size: 16px;
  font-weight: 500;
  line-height: 19.68px;
  text-align: left;
  color: #0a1126;
  padding-bottom: 10px !important;
}
#dropdown-basic-dispute {
  width: 100% !important;
  height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffff;
  border-radius: 12px;
  border: 1px solid #eaeaea;
  position: relative;
}
.dropdown_title {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: black;
}
.dropdown_placeholder {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #858585;
}
.dropdown__image-dispute {
  position: absolute;
  right: 4%;
  /* padding-right: 23px; */
}
.dispute__dropdown-body {
  width: 100%;
  border: 1px solid #eaeaea !important;
  border-radius: 12px !important;
}
.dispute__body label {
  /* font-family: Clash Grotesk Variable; */
  font-size: 16px;
  font-weight: 500;
  line-height: 19.68px;
  text-align: left;
  color: #0a1126;
  padding-top: 20px !important;
  padding-bottom: 10px !important;
}
.dispute__body textarea {
  width: 100%;
  height: 103px;
  border: 1px solid #eaeaea;
  border-radius: 12px;
  padding-top: 20px !important;
  padding-left: 22px !important;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: #0a1126;
}
.dispute__body textarea::placeholder {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #858585;
}

.no-project-item {
  padding: 0.25rem 1rem !important;
}
