h1 , h2 , h3 ,h4 , h5 , h6{
  font-weight: 0;
  margin: 0;
}

.add-project-modal .modal-dialog {
  max-width: 580px;
}
.add-project-modal .modal-header {
  /* border-radius: 20px 20px 0 0 !important; */
  border-bottom: none !important;
}
.add-project-modal .modal-content {
  border-radius: 20px !important;
}
.add-project__header {
  display: flex;
  justify-content: space-between !important;
  width: 100%;
  border: none !important;
}
.add-project__main-header h6 {
  font-family: "ClashGrotesk";
  font-size: 24px;
  font-weight: 550;
  line-height: 29.52px;
  text-align: left;
  color: #0a1126;
}
.add-project__main-header p {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #858585;
}
.add-project__input {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.add-project__input label {
  font-family: "ClashGrotesk";
  font-size: 16px;
  font-weight: 500;
  line-height: 19.68px;
  text-align: left;
  color: #0a1126;
  padding-bottom: 10px;
}
.add-project__input input {
  width: 440px;
  height: 64px;
  border-radius: 12px;
  border: 1px solid #eaeaea;
  padding-left: 22px;
}
.add-project__input2 {
  display: flex;
  flex-direction: column;
}
.add-project__input2 label {
  font-family: "ClashGrotesk";
  font-size: 16px;
  font-weight: 500;
  line-height: 19.68px;
  text-align: left;
  color: #0a1126;
  padding-bottom: 10px;
}
.add-project__input2 textarea {
  width: 440px;
  height: 130px;
  border-radius: 12px;
  border: 1px solid #eaeaea;
  padding-left: 22px;
  padding-top: 20px;
  font-family: "ClashGrotesk";
}
.addproj-textarea:focus {
 border:1px solid red !important;
}

.add-project__main-btn {
  width: 100%;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.add-project__btn {
  width: 440px !important;
  height: 67px !important;
  border: 1px solid #166fbf0a !important;
  background: #0a1126 !important;
  padding: 20px !important;
  border-radius: 12px !important;
  text-transform: none !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  line-height: 27px !important;
  color: #ffffff !important;
  margin-top: 40px !important;
  font-family: "ClashGrotesk";
}
input::placeholder {
  color: #0a1126 !important;
}

@media (max-width: 768px) {
  .add-project-modal .modal-header {
    padding: 30px 30px 0px 30px !important;
  }
  .add-project__main-header h6 {
    font-size: 20px;
    line-height: 24.6px;
  }
  .add-project__main-header p {
    font-size: 14px !important;
    line-height: 21px !important;
    padding-top: 7px !important;
  }
  .add-project-modal .modal-body {
    padding: 40px 30px 33px 30px !important;
  }
  .add-project__input input {
    width: 100% !important;
  }
  .add-project__input {
    width: 100%;
  }
  .add-project__input2 textarea {
    width: 100% !important;
  }
  .upload-project__btn {
    width: 100% !important;
    font-size: 16px !important;
    line-height: 24px !important;
    margin-top: 40px !important;
  }
}
